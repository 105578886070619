import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import {
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";

import {
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
  FaWhatsapp,
} from "react-icons/fa";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import {
  mainBlack,
  mainRed,
  mainWhite,
  mainYellow,
  transHover,
  innerWidth,
  lightGrey,
  screen,
} from "../components/common/variables";
import { formatDate, countWords, toSlug, reduceWords } from "../helpers";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";

const Wrapper = styled.div`
  .banner {
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      margin: 0;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
    }
    .featured-txt {
      .inner-txt {
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }

        .back-link {
          color: ${mainBlack};
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 30px 0;
          font-size: 0.88rem;
          letter-spacing: 2px;
          text-align: center;
          @media ${screen.xsmall} {
            margin: 0 0 40px 0;
          }
          @media ${screen.small} {
            justify-content: flex-start;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainYellow};
            }
          }
          svg {
            font-size: 2rem;
            margin: 0 10px 0 0;
          }
        }

        .normal-p {
          text-align: center;
          margin: 10px 0 0 0;
          display: block;
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
          }
        }

        .share-links {
          margin: 30px 0 0 0;
          text-align: center;
          @media ${screen.xsmall} {
            display: flex;
            align-items: center;
          }

          .share-label {
            color: ${mainRed};
            display: block;
            font-size: 0.88rem;
            font-weight: 400;
            letter-spacing: 3px;
            text-transform: uppercase;
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              color: ${mainBlack};
              margin: 0;
              font-size: 1.25rem;
            }
            @media ${screen.small} {
              display: inline;
            }
            @media ${screen.large} {
              font-size: 1.42rem;
            }
          }

          button {
            outline: none;
          }

          svg {
            font-size: 1.18rem;
            margin: 0 20px;
            transition: ${transHover};
            @media ${screen.xsmall} {
              margin: 0 0 0 25px;
              font-size: 1.4rem;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainYellow};
              }
            }
          }
        }
      }
    }
  }
  .main-content {
    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        text-align: center;
        @media ${screen.small} {
          width: 45%;
          text-align: left;
        }

        &--left {
          border-bottom: 2px solid ${mainRed};
          margin: 0 0 25px 0;
          padding: 0 0 20px 0;
          @media ${screen.small} {
            border-bottom: none;
            padding: 0 60px 0 0;
          }

          h5 {
            font-size: 1.22rem;
            font-weight: 400;
            @media ${screen.xsmall} {
              font-size: 1.4rem;
            }
            @media ${screen.large} {
              font-size: 1.5rem;
            }
          }
        }

        &--right {
          @media ${screen.small} {
            width: 55%;
          }

          .description {
            p {
              font-size: 1rem;
              font-weight: 300;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.1rem;
              }
            }

            a {
              color: ${mainRed};
              text-decoration: underline;
              @media ${screen.withCursor} {
                &:hover {
                  opacity: 0.7;
                }
              }
            }

            ul,
            ol {
              @media ${screen.small} {
                padding: 0 0 0 30px;
              }

              li {
                font-size: 1rem;
                font-weight: 300;
                margin: 0 0 20px 0;
                line-height: 1.5;
                @media ${screen.large} {
                  font-size: 1.1rem;
                }
              }
            }

            ol {
              li {
                list-style-type: disc;
                list-style-position: inside;
              }
            }

            ul {
              li {
                list-style-type: decimal;
                list-style-position: inside;
              }
            }

            h1 {
              font-size: 1.7rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.8rem;
              }
            }

            h2 {
              font-size: 1.6rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.7rem;
              }
            }

            h3 {
              font-size: 1.5rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.6rem;
              }
            }

            h4 {
              font-size: 1.4rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }

            h5 {
              font-size: 1.3rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.4rem;
              }
            }

            h6 {
              font-size: 1.2rem;
              font-weight: 500;
              margin: 0 0 20px 0;
              @media ${screen.large} {
                font-size: 1.3rem;
              }
            }

            img {
              display: block;
              width: 100%;
              margin: 30px 0;
            }

            div {
              position: relative;
              width: 100%;
              padding-top: 56.25%;
              margin: 20px 0;
              @media ${screen.xsmall} {
                margin: 29px 0;
              }
            }
            iframe {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100% !important;
            }
          }

          .btns {
            display: flex;
            justify-content: space-between;
            margin: 68px 0 0 0;
            @media ${screen.xsmall} {
              margin: 100px 0 0 0;
            }

            &__btn {
              color: ${mainBlack};
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.88rem;
              letter-spacing: 2px;
              text-align: center;
              @media ${screen.small} {
                justify-content: flex-start;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainYellow};
                }
              }

              svg {
                font-size: 2rem;
              }

              &--prev {
                svg {
                  margin: 0 10px 0 0;
                }
              }

              &--next {
                svg {
                  margin: 0 0 0 10px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const BlogPage = ({ data }) => {
  const {
    title_tag,
    meta_description,
    subtitle,
    description,
    edit_published_date,
    featured_image,
    title,
  } = data.content.data;
  const shareURL = typeof window !== "undefined" && window.location.href;
  const publishedDate = edit_published_date
    ? edit_published_date
    : data.content.first_publication_date;

  // get all article titles ordered by date, to use for next/previous buttons
  const allArticleTitles = data.allArticles.edges
    .map((item) => {
      return {
        // if edit published date is empty, use the default first publication date from Prismic
        published_date: item.node.data.edit_published_date
          ? Number(item.node.data.edit_published_date.split("-").join(""))
          : Number(
              item.node.first_publication_date.slice(0, 10).split("-").join("")
            ),
        title: item.node.data.title.text,
      };
    })
    // sort by formatted date as a number (e.g 20201225 - 20191225)
    .sort((a, b) => b.published_date - a.published_date)
    // final return only the string title
    .map((item) => item.title);

  // get current blog index in array
  const currentBlogIndex = allArticleTitles.indexOf(title.text);

  // get previous event title next to current blog by -1 index
  const previousArticle = allArticleTitles[currentBlogIndex - 1];

  // get next event title after the current blog by +1 index
  const nextArticle = allArticleTitles[currentBlogIndex + 1];

  return (
    <Layout>
      <SEO
        title={title_tag || title.text}
        description={
          meta_description ||
          (description && description.text && reduceWords(description.text))
        }
        image={featured_image.url}
        articleInfo={{
          headline: `Australia's Street Choir! ${title.text}`,
          alternativeHeadline: `${title.text}, read now!`,
          image: featured_image.thumbnails.thumbnail.url,
          wordcount: countWords(description.text),
          url: shareURL,
          datePublished: publishedDate,
          description: subtitle,
          articleBody: description.text,
        }}
      />
      <Wrapper>
        <SubBanner
          className="banner"
          bgUrl={featured_image.thumbnails.thumbnail.url}
        >
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <Link className="back-link" to="/news/">
                  <BsArrowLeft />
                  BACK TO ALL NEWS
                </Link>
                <h1 className="capital-heading">{title.text}</h1>
                <span className="normal-p">
                  Published {formatDate(publishedDate)}
                </span>
                <div className="share-links">
                  <span className="share-label">SHARE</span>
                  <FacebookShareButton
                    url={shareURL}
                    children={<FaFacebookF />}
                  />
                  <LinkedinShareButton
                    url={shareURL}
                    children={<FaLinkedinIn />}
                  />
                  <WhatsappShareButton
                    url={shareURL}
                    children={<FaWhatsapp />}
                  />
                  <TwitterShareButton url={shareURL} children={<FaTwitter />} />
                </div>
              </div>
            </div>
          </div>
        </SubBanner>
        <section className="main-content">
          <div className="inner-wrapper">
            <div className="col col--left">
              <h5>{subtitle}</h5>
            </div>
            <div className="col col--right">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: description.html }}
              />

              <div className="btns">
                <Link
                  className="btns__btn btns__btn--prev"
                  style={{ visibility: previousArticle ? "visible" : "hidden" }}
                  to={`/news/${toSlug(previousArticle || "/")}`}
                >
                  <BsArrowLeft />
                  PREVIOUS
                </Link>

                <Link
                  className="btns__btn btns__btn--next"
                  style={{ visibility: nextArticle ? "visible" : "hidden" }}
                  to={`/news/${toSlug(nextArticle || "/")}`}
                >
                  NEXT
                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default BlogPage;
export const data = graphql`
  query($id: String!) {
    content: prismicArticle(id: { eq: $id }) {
      first_publication_date
      data {
        title {
          text
        }
        featured_image {
          alt
          url
          thumbnails {
            thumbnail {
              url
            }
          }
        }
        subtitle
        description {
          html
          text
        }
        edit_published_date
        title_tag
        meta_description
      }
    }
    allArticles: allPrismicArticle {
      edges {
        node {
          first_publication_date
          data {
            title {
              text
            }
            edit_published_date
          }
        }
      }
    }
  }
`;
