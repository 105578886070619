// banner used for all pages except home,about,corporate workshops and school programs
// also used on a page with large image on the side
import React from "react";
import styled from "styled-components";
import {
  mainBlack,
  screen,
  innerWidth,
} from "../../components/common/variables";

const Wrapper = styled.div`
  background: ${(props) => (props.bgColour ? props.bgColour : mainBlack)};
  overflow: hidden;
  position: relative;

  .sub-wrapper {
    display: flex;
    flex-direction: column;
    @media ${screen.small} {
      display: block;
    }
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 41px 22px 50px 22px;
    display: flex;
    flex-direction: column;
    @media ${screen.xsmall} {
      padding: 100px 42px;
    }
    @media ${screen.small} {
      align-items: center;
      flex-direction: row;
      justify-content: ${(props) => (props.bgLeft ? `flex-end` : `flex-start`)};
      padding: 100px 30px;
      min-height: 610px;
    }
    @media ${screen.large} {
      padding: 110px 0;
    }

    .featured-txt {
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        width: ${(props) => (props.bgLeft ? `55%` : `45%`)};
      }

      .inner-txt {
        @media ${screen.small} {
          margin: ${(props) => (props.bgLeft ? `0 0 0 12%` : `0 12% 0 0`)};
        }
      }
    }
  }

  .absolute-bg {
    background-image: ${(props) => props.bgUrl && `url(${props.bgUrl})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 255px;
    order: -1;
    @media ${screen.xsmall} {
      height: 480px;
    }
    @media ${screen.small} {
      width: ${(props) => (props.bgLeft ? `45%` : `55%`)};
      position: absolute;
      right: ${(props) => (props.bgLeft ? `auto` : `0`)};
      left: ${(props) => (props.bgLeft ? `0` : `auto`)};
      height: 100.6%;
      top: 50%;
      transform: translateY(-50%);
      order: 0;
    }
  }
`;

const SubBanner = ({ children, className, bgUrl, bgLeft, bgColour }) => {
  return (
    <Wrapper
      className={className}
      bgUrl={bgUrl}
      bgColour={bgColour}
      bgLeft={bgLeft}
    >
      <div className="sub-wrapper">
        {/* position image based on bgLeft prop */}

        {bgLeft && <div className="absolute-bg"></div>}
        {children}
        {!bgLeft && <div className="absolute-bg"></div>}
      </div>
    </Wrapper>
  );
};

export default SubBanner;
